body {
  font-family: 'Titillium Web', sans-serif;
  background: hsl(240, 2%, 4%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: hsl(240, 24%, 94%);
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

.raised {
  background: linear-gradient(hsla(240, 20%, 6%, 0.6), hsla(240, 30%, 4%, 0.6));
  border-radius: 36px;
  border: 0.5px solid hsla(240, 30%, 70%, 0.3);
  box-shadow: 2px 12px 25px -4px hsla(242, 43%, 11%, 0.6);
  box-shadow: 2px 5px 0px 0px hsla(242, 43%, 21%, 0.7);
}

#demo-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.1em;
  padding: 10px;
  color: hsl(350, 50%, 85%);
  background: linear-gradient(hsl(336, 70%, 50%), hsl(350, 80%, 40%));
}

#banner-left, #banner-mid, #banner-right {
  min-width: 304px;
}

#banner-mid {
  flex-grow: 1;
  text-align: center;
}

#banner-right a {
  padding: 0 10px;
  color: hsl(350, 50%, 85%);
}

#navbar {
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: space-between;
}

#navbar * {
  display: flex;
  flex-direction: row;
}

#navbar-header, #navbar-footer {
  min-width: 160px;
}

#navbar-footer {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

#navbar-footer, #navbar-body {
  font-size: 1.2em;
}

#navbar button {
  background: none;
  font-size: 1em;
  font-weight: lighter;
  color: #e1e1e1;
}

#navbar button:hover {
  background: rgba(255, 255, 255, 0.07);
}

#navbar button:active {
  background: rgba(255, 255, 255, 0.25);
}

#navbar button.selected {
  color: #40dbe4;
}

#app-body {
  display: flex;
  flex-direction: column;
  width: 1110px;
  align-self: center;
}

#plan-graph-container {
  flex: 1;
  padding: 40px;
  margin-bottom: 40px;
}

#plan-graph-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  font-size: 0.9em;
}

#plan-graph-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
}

#plan-graph-tooltip-message p {
  margin: 0;
  padding: 0;
}

#plan-graph-tooltip-message {
  color: hsl(240, 20%, 92%);
  font-weight: normal;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 210px;
  padding: 20px;
}

.simulation-warning {
  color: yellow;
  margin-left: 5px;
  max-width: 270px;
}

.simulation-warning .icon {
  margin-bottom: -2px;
}

#unaccounted-for-cashflow-toggle, #deflation-toggle {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

#unaccounted-for-cashflow-toggle:hover, #deflation-toggle:hover {
  cursor: pointer;
}

#plan {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
}

.monthyear-selector-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.monthyear-selector-row select {
  width: 50%;
}

.event-selector {
  position: relative;
}

.event-selector button {
  border-radius: 6px;
  min-width: 120px;
  margin: 0;
  padding: 8px 0px;
  border: none;
}

.date-editor {
  background: linear-gradient(hsla(240, 20%, 6%, 0.9), hsla(240, 30%, 4%, 0.9));
  border: 0.5px solid hsla(240, 30%, 70%, 0.3);
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  border-radius: 22px;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  width: 170px;
  padding: 20px;
  z-index: 1;
}

.date-editor .divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: hsl(240, 20%, 30%);
  width: 100%;
}

.date-editor .divider .rule {
  border-top: 1px solid hsl(240, 14%, 30%);
  flex-grow: 1;
  height: 1px;
  margin: 6px;
}

.date-editor select {
  text-align: center;
}

.date-editor select[disabled] {
  color: hsla(240, 24%, 66%, 0.8);
  background: hsla(240, 24%, 10%, 0.8);
  box-shadow: 0.5px 1px 0px 0px hsla(242, 43%, 51%, 0);
}

.monthyear-editor {
  display: flex;
  flex-direction: column;
  background: #101010;
  background: #e1e1e1;
  position: absolute;
  border-radius: 4px;
  padding: 0px;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -90px;
}

.event-selector select {
  margin: 5px 0px;
  align-self: center;
  width: 100%;
}

.stream-list {
  margin-left: 16px;
  margin-bottom: 20px;
  padding-left: 40px;
  background: none;
}

#plan-editor {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  height: 400px; /* TODO check this*/
  width: 100%;
  align-self: center;
}

#plan-editor-nav {
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  min-width: 200px;
}

.editor-nav-icon {
  margin-bottom: -2px;
}

#plan-editor-nav button {
  font-size: 1.2em;
  background: none;
  border-radius: 6px;
  color: hsl(240, 10%, 50%);
  text-align: left;
  margin-right: 26px;
  margin-left: 26px;
  padding: 0.4em 0.6em;
}

#plan-editor-nav button.selected {
  color: hsl(240, 10%, 97%);
}

#plan-editor-nav button:hover {
  background: hsla(242, 43%, 11%, 0.3);
  cursor: pointer;
}

#plan-editor-nav button:active {
  color: hsl(240, 10%, 97%);
  background: hsla(242, 43%, 11%, 0.8);
}

#plan-editor-nav button.selected:active {
  background: black;
}

#plan-editor-container {
  height: 100%;
  width: 100%;
}

#plan-editor-body {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 40px;
  padding-left: 40px;
  align-items: left;
  height: calc(100% - 80px);
  overflow-y: auto;
  border-left: 0.5px solid hsla(240, 30%, 70%, 0.3);
}

#plan-editor-body .row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

#plan-editor-body .row * {
  box-sizing: border-box;
}

#plan-editor-body .row.editor-label {
  margin-left: 10px;
}

#plan-editor-body .row * input {
  width: 100%;
}

#about-you-editor #net-worth {
  font-size: 1.6em;
  margin-bottom: 20px;
  padding-left: 12px;
}

#about-you-editor #net-worth span {
  color: hsl(58, 97%, 67%);
  font-size: 1.2em;
}

.editor-button {
  color: hsla(240, 42%, 90%, 0.96);
  background: hsl(240, 22%, 12%);
  border: 0.5px solid hsla(240, 30%, 70%, 0.3);
  border-radius: 36px;
  margin-top: 2px;
  margin-bottom: 20px;
  padding: 10px 22px 10px 22px;
  min-width: 80px;
  box-shadow: 0.5px 1px 0px 0px hsla(242, 43%, 51%, 0.7);
}

.editor-button:hover {
  background: hsl(240, 22%, 16%);
  cursor: pointer;
}

.editor-button:active {
  transform: translate(1px, 1px);
  box-shadow: none;
}

#expense-baseline {
  display: flex;
  flex-direction: row;
}

#expense-baseline-tooltip-body {
  min-width: 260px;
}

.editor-action {
  margin-top: 7px;
}

.income-editor, .accounts-editor, .debt-editor {
  color: #e1e1e1;
}

.age,
.filing-status,
.cashflow-destination,
.value,
.salary,
.amount,
.account-type,
.contribution,
.per,
.date,
.event,
.balance,
.payment,
.rate,
.note,
.editor-action {
  margin-left: 5px;
  margin-right: 5px;
}

.date {
  width: 190px;
  min-width: 190px;
}

.event {
  width: 117px;
  min-width: 117px;
}

.filing-status select, .cashflow-destination select {
  width: 100%;
}

.value, .salary, .amount, .account-type, .contribution, .balance, .per {
  width: 100px;
  min-width: 100px;
}

.filing-status, .cashflow-destination {
  width: 110px;
  min-width: 110px;
}

.age, .payment, .rate {
  width: 80px;
  min-width: 80px;
}

.note {
  width: 400px;
  min-width: 400px;
}

.editor-action {
  width: 20px;
  min-width: 20px;
}

.note input {
  text-align: left;
}

.header-icon {
  vertical-align: middle;
  margin-bottom: 10px;
}

.columned-left {
  margin-right: 10px;
}

.columned-right {
  margin-left: 10px;
}

.adjustment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#feedback-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  background: hsla(240, 50%, 2%, 0.6);
  border: 0.5px solid hsla(240, 30%, 70%, 0.3);
  border-radius: 6px;
  width: 95%;
  margin-bottom: 40px;
}

#feedback-box input {
  color: hsl(240, 10%, 65%);
  background: none;
  border: none;
  width: 100%;
  padding: 10px 20px;
  text-align: left;
}

#feedback-box input:focus {
  outline: none;
}

#feedback-box button {
  color: hsl(240, 10%, 65%);
  padding: 0px 30px;
  background: hsl(242, 23%, 10%);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-radius: 0px;
  margin: 6px;
}

#simulator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#graph-container {
  margin-left: 20px;
  border-radius: 24px;
  margin-bottom: 40px;
  padding: 30px;
  height: 600px;
  width: 1000px;
}

#graph-container-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#graph-legend {
  right: 0;
  z-index: 2;
  position: absolute;
  margin-right: 20px;
  min-width: 190px;
}

#graph-legend li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  list-style: none;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px;
}

#graph-loading {
  font-size: 3em;
  position: absolute;
}

#graph {
  height: 85%;
  width: 100%;
}

#fanchart-select {
  color: white;
  font-size: 1.6em;
  padding: 0px 16px;
  margin-bottom: 10px;
  border-radius: 6px;
  text-align: left;
}

select:focus:not(:focus-visible) {
  outline: none
}

#fanchart-select option {
  background: #333;
}


.button-icon {
  vertical-align: middle;
  margin-top: 5px;
}

button {
  background-color: #eee;
  border: none;
  border-radius: 40px;
  padding: 0.5em 1em;
  font-family: 'Titillium Web', sans-serif;
}

button:hover {
    background-color: rgba(255, 255, 255, 0.03);
}

button:active {
    background-color: rgba(255, 255, 255, 0.11);
}

label {
    padding-bottom: 4px;
}

input {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.1em;
  border: none;
  background: hsla(240, 50%, 2%, 0.6);
  border: 0.5px solid hsla(240, 30%, 70%, 0.3);
  color: #eee;
  border-radius: 6px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: right;
}

input[type=checkbox] {
  box-shadow: none;
}

li:not(:last-child) {
    margin-bottom: 0.4em;
}

select {
  color: hsla(240, 42%, 90%, 0.96);
  font-size: 0.9em;
  font-family: 'Titillium Web', sans-serif;
  background: black;
  background: hsl(240, 22%, 12%);
  border: none;
  border-right: 4px solid transparent;
  border-radius: 6px;
  padding: 6px 3px 6px 7px;
  text-align: left;
  box-shadow: 0.5px 1px 0px 0px hsla(242, 43%, 51%, 0.7);
}

select:focus,
input:focus {
  outline: 1px solid rgba(63, 174, 252, .7);
}

.slim {
    margin: 0;
    padding: 0;
    color: #eee;
    background: none;
}

.slim:hover {
    background: none;
    color: #eaeaea;
}

.slim:active {
    background: none;
    color: #afafaf;
}

.form-div {
  /*width: 385px;*/
  width: 450px;
  background-color: #0f0f0f;
  display: inline-block;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.labeled-div {
  display: flex;
  flex-direction: column;
}

.tooltip {
  position: relative;
}

.tooltip-body {
  visibility: hidden;
  position: absolute;
  background: linear-gradient(45deg, hsla(240, 30%, 30%, 0.6), hsla(240, 20%, 60%, 0.4));
  backdrop-filter: blur(8px);
  border-radius: 22px;
  border: 0.5px solid hsl(240, 10%, 40%);
  padding: 6px 12px;
  opacity: 0;
  transition: opacity 0.1s;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.tooltip:hover .tooltip-body {
  visibility: visible;
  opacity: 1;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: hsla(0, 0%, 0%, 0.4);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-content {
    display: flex;
    flex-direction: column;
    padding: 0.5em 2.5em 3em 2.5em;
    width: 550px;
    background: radial-gradient(
        ellipse at top right,
        hsla(240, 32%, 70%, 0.6),
        hsla(240, 50%, 22%, 0.6)
    );
    border-radius: 20px;
    min-height: 300px;
    height: 60%;
    max-height: 700px;
}

.modal-body {
    overflow-y: auto;
}

.scrolly::-webkit-scrollbar {
    width: 0.6em;
}

.scrolly::-webkit-scrollbar-thumb {
    background-color: hsl(240, 40%, 96%);
    border-radius: 1em;
    border: 0.2em solid transparent;
    background-clip: padding-box; /* thank u: https://stackoverflow.com/a/70089996 */
}

::-webkit-scrollbar-thumb:hover {
    border: 0;
}
